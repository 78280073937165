import React, { Component } from 'react'
import ContactEn from './email_form/ContactFormEn'
// import TestImage from '../scss/images/_02_1.jpg'
import TestImage from '../scss/images/inquiry_new_c.jpg'

class ContactPartEn extends Component {
  render() {
    return (
      <div className="inquiryPageWrapper section section--gradient">
        <div className="heroAboutUsContentHolder heroInquiryHolder">
          <div className="heroAboutUsImageHolder">
            <div className="customWrapper">
              <div className="bottomHalfText">
                <div className="whitetitleHolder">
                  <h1 className="whiteTitleHalf">INQUIRY</h1>
                </div>
              </div>
              <div
                className="blendModeHolder"
                style={{ backgroundImage: `url(${TestImage})` }}
              >
                <div className="topHalfText">
                  <h1 className="coloredTitleHalf">INQUIRY</h1>
                </div>
              </div>
            </div>
            <img src={TestImage} alt="" />
          </div>

          {/* <div className="heroAboutUsTextGrid">
          <div className="topRightContent">
            <h1>ANA NENADOVIC ARCHITECTS</h1>
            <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionOne }} />
          </div>
          <div className="bottomLeftContent">
            <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionTwo }} />
          </div>
        </div> */}
        </div>
        <section className="contactFormInquiry" id="contactInquiry">
          <div className="inquiry_wrapper">
            <ContactEn />
          </div>
        </section>
      </div>
    )
  }
}

export default ContactPartEn
