import React, { Component } from 'react'
import axios from 'axios'
import { window, document } from 'browser-monads'

const API_PATH = 'https://ananenadovic.com/email_form.php'

function inputReadFiles() {
  if (typeof window !== 'undefined');
  ;(function(window, index) {
    const inputs = document.querySelectorAll('.inputfile')
    Array.prototype.forEach.call(inputs, input => {
      const label = input.nextElementSibling
      const labelVal = label.innerHTML

      input.addEventListener('change', function(e) {
        let fileName = ''
        if (this.files && this.files.length > 1)
          fileName = (this.getAttribute('data-multiple-caption') || '').replace(
            '{count}',
            this.files.length
          )
        else fileName = e.target.value.split('\\').pop()

        if (fileName) label.querySelector('span.input_txt').innerHTML = fileName
        else label.innerHTML = labelVal
      })

      // Firefox bug fix
      input.addEventListener('focus', () => {
        input.classList.add('has-focus')
      })
      input.addEventListener('blur', () => {
        input.classList.remove('has-focus')
      })
    })
  })(window, 0)
}
class ContactOneEn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      surname: '',
      email: '',
      deadline: '',
      address: '',
      area: '',
      selectedFiles: [],
      mailSent: false,
      error: null,
      window: '',
    }
    this.fileSelectedHandler = this.fileSelectedHandler.bind(this)
    this.handleUserEmail = this.handleUserEmail.bind(this)
    // this.inputReadFiles = this.inputReadFiles(this)
    // this.deadlineHandler = this.deadlineHandler.bind(this)
  }

  //   componentDidMount() {
  //     this.inputReadFiles = setInterval(() => this.inputReadFiles(), 1000)
  //   }

  fileSelectedHandler = event => {
    console.log(event.target.files)
    this.setState({
      selectedFiles: event.target.files,
    })
  }
  //   deadlineHandler = event => {
  //     console.log(event.target.files)
  //     this.setState({
  //       selectedFiles: event.target.files,
  //     })
  //   }

  handleFormSubmit = e => {
    e.preventDefault()
    console.log(this.state)
    axios({
      method: 'post',
      url: `${API_PATH}`,
      headers: {
        'content-type': 'multipart/form-data',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'Origin, X-Requested-With, Content-Type, Accept',
        'Access-Control-Allow-Methods': 'GET, POST',
      },
      data: this.state,
    })
      .then(result => {
        this.setState({
          mailSent: result.data.mailSent,
        })
      })
      .catch(error => this.setState({ error: error.message }))
  }

  //   // handle email
  handleUserEmail = event => {
    this.setState(
      {
        email: event.target.value,
      },
      function() {
        console.log(this.state.email)
      }
    )
  }

  render() {
    // const isEnabled = this.canBeSubmitted()
    setTimeout(() => {
      inputReadFiles()
    }, 700)

    return (
      <div className="contact_content">
        <form
          action="https://ananenadovic.com/email_form.php"
          method="post"
          encType="multipart/form-data"
          onKeyPress={e => {
            e.key === 'Enter' && e.preventDefault()
          }}
        >
		<div className="heroAboutUsTextGrid form_grid">
			<div className="middle_element">
				<div className="topRightContent topRightInquiry top_text">
					<h1>ANA NENADOVIC ARCHITECTS</h1>
					{/* <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionOne }} /> */}
					<div>
					If you have a project you would like us to work on, please fill
					out this form.
					<br />
					Fill in your personal details, the address of the interior, and
					its area in square meters.
					<br />
					If you have a tight schedule to get your project completed, feel
					free provide us with a deadline as well.
					</div>
				</div>
				<div className="topRightInputs middle_inputs">
					<label htmlFor="name">
						Name *
						<input
						name="name"
						type="text"
						placeholder="Your Name"
						value={this.state.name}
						onChange={e => this.setState({ name: e.target.value })}
						required
						/>
						{/* <span className="error">{this.state.error.name}</span> */}
					</label>
					<label htmlFor="surname">
						Surname *
						<input
						name="surname"
						type="text"
						placeholder="Your Surname"
						value={this.state.surname}
						onChange={e => this.setState({ surname: e.target.value })}
						required
						/>
						{/* <span className="error">{this.state.error.name}</span> */}
					</label>

					<label className="emailLabel" htmlFor="email">
						Your email *
						<input
						type="email"
						name="email"
						placeholder="Please enter a valid email address"
						value={this.state.email}
						// onChange={e => this.setState({ email: e.target.value })}
						onChange={this.handleUserEmail}
						required
						/>
						{/* <span className="error">{this.state.error.email}</span> */}
					</label>

					<label className="addressLabel" htmlFor="address">
						Address of Interior
						<input
						name="address"
						type="text"
						placeholder="Please enter address of the location"
						value={this.state.address}
						onChange={e => this.setState({ address: e.target.value })}
						// onChange={this.handleUserAddress}
						/>
						{/* <span className="error">{this.state.error.address}</span> */}
					</label>
					<label className="areaLabel" htmlFor="area">
					Area in m2
					<input
					type="number"
					name="area"
					min="0"
					placeholder="Please enter area of Interior in m2"
					value={this.state.area}
					onChange={e => this.setState({ area: e.target.value })}
					/>
					{/* <span className="error">{this.state.error.area}</span> */}
				</label>
				</div>
				<div className="bottomLeftContent bottomLeftInquiry middle_text">
					{/* <div dangerouslySetInnerHTML={{ __html: whoWeAreSectionTwo }} /> */}
					Send us a couple of images of the current space in question.
					<br />
					Send us a mood board of the atmosphere you want to achieve with
					your interior. Any picture or idea that you have will do!
					<br />
				</div>
				<div className="bottomRightInputs bottom_inputs">
					<div className="box">
						<input
						type="file"
						name="my_files[]"
						id="file-2"
						className="inputfile inputfile-2"
						data-multiple-caption="{count} files selected"
						onChange={this.fileSelectedHandler}
						multiple
						/>
						<label htmlFor="file-2">
							<div className="labelWrap">
								<div className="svgWrap">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="17"
										viewBox="0 0 36 33"
										>
										<path fill="#000" fillOpacity=".5" d="M34.191 15.904c-.56 0-1.008.448-1.008 1.009v9.173a4.526 4.526 0 01-4.52 4.52H6.537a4.526 4.526 0 01-4.519-4.52v-9.323c0-.56-.448-1.008-1.009-1.008-.56 0-1.008.448-1.008 1.008v9.323a6.544 6.544 0 006.537 6.537h22.127a6.544 6.544 0 006.536-6.537v-9.173c0-.553-.448-1.009-1.009-1.009z"/>
										<path fill="#000" fillOpacity=".5" d="M11.9 8.135l4.691-4.691v20.46c0 .561.449 1.01 1.009 1.01.56 0 1.008-.449 1.008-1.01V3.445L23.3 8.135c.194.194.456.299.71.299a.972.972 0 00.71-.299 1.006 1.006 0 000-1.427L18.31.298A1.02 1.02 0 0017.6 0a.977.977 0 00-.71.299l-6.41 6.41a1.006 1.006 0 101.42 1.426z"/>
									</svg>
								</div>
								<div className="textBtnWrap">
									<span className="input_txt">Images of Interior</span>
									<div className="info_about_upload">
										*Please insert up to 5 images not over 1mb each
									</div>
								</div>
							</div>
						</label>
						{/* <span>Choose files&hellip;</span> */}
					</div>
					<div className="box">
						<input
						type="file"
						name="atmosphere_files[]"
						id="atmosphere"
						className="inputfile inputfile-2"
						data-multiple-caption="{count} files selected"
						placeholder="Please upload images of the area in question"
						// onChange={this.fileSelectedOtherFiles}
						multiple
						/>
						<label htmlFor="atmosphere">
							<div className="labelWrap">
								<div className="svgWrap">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="20"
										height="17"
										viewBox="0 0 36 33"
									>
										<path fill="#000" fillOpacity=".5" d="M34.191 15.904c-.56 0-1.008.448-1.008 1.009v9.173a4.526 4.526 0 01-4.52 4.52H6.537a4.526 4.526 0 01-4.519-4.52v-9.323c0-.56-.448-1.008-1.009-1.008-.56 0-1.008.448-1.008 1.008v9.323a6.544 6.544 0 006.537 6.537h22.127a6.544 6.544 0 006.536-6.537v-9.173c0-.553-.448-1.009-1.009-1.009z"/>
										<path fill="#000" fillOpacity=".5" d="M11.9 8.135l4.691-4.691v20.46c0 .561.449 1.01 1.009 1.01.56 0 1.008-.449 1.008-1.01V3.445L23.3 8.135c.194.194.456.299.71.299a.972.972 0 00.71-.299 1.006 1.006 0 000-1.427L18.31.298A1.02 1.02 0 0017.6 0a.977.977 0 00-.71.299l-6.41 6.41a1.006 1.006 0 101.42 1.426z"/>
									</svg>
								</div>
								<div className="textBtnWrap">
									<span className="input_txt">Your Mood Board</span>
									<div className="info_about_upload">
									*Please insert up to 5 images not over 1mb each
									</div>
								</div>
							</div>
						</label>
					</div>
					<label className="deadlineLabel" htmlFor="deadline">
						Your deadline
						<input
						type="text"
						name="deadline"
						placeholder="Please send us a deadline if you have one"
						value={this.state.deadline}
						onChange={e => this.setState({ deadline: e.target.value })}
						// onChange={this.deadlineHandler}
						/>
					</label>
				</div>
			</div>
		</div>

          {/* //button upload/sendd */}
          <div className="sendButtonWrapper">
            <label>
              <input
                className="sendInput"
                type="submit"
                name="button"
                value="Submit"
              />
            </label>
            {/* <button
                // disabled={!isEnabled}
                type="submit"
                className="sendBtnInquiry"
                value="Send"
                // onClick={this.fileUploadHandler}
                // onClick={this.onFormSubmit}
                onClick={e => this.handleFormSubmit(e)}
              >
                Send Email with all input fields
              </button> */}
            {/* <input
                className="sendBtnInquiry"
                type="submit"
                value="Send"
                onClick={e => this.handleFormSubmit(e)}
              /> */}
          </div>
          <div className="contact_form_info">
            {this.state.mailSent && (
              <div>
                Thank you for contacting us, we will get back to you shortly!
              </div>
            )}
          </div>
        </form>
      </div>
    )
  }
}

export default ContactOneEn
